function SearchQuery({onSearchQueryChange, searchQuery, clearSearch}) {
  return (
    <div className="search-container">
      <input
        className="search-input"
        type="text"
        placeholder="Поиск по доменам..."
        value={searchQuery}
        onChange={onSearchQueryChange}
      />
      {searchQuery && (
        <button className="clear-button" onClick={clearSearch}>
          &times; ebfrhkwjehrfkw
        </button>
      )}
    </div>
  );
}
export default SearchQuery;
