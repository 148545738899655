import React, { useState } from 'react';
import '../App.css';
import Header from '../components/Header';
  
function Checklist({ name }) {
  const [activeTab, setActiveTab] = useState('mainDomain'); 

  const domainFields = {
    mainDomain: [
      { id: 1, name: 'redirect', label: 'Редирект с гет параметров на главную страницу' },
      { id: 3, name: 'errorPage', label: '404' },
      { id: 8, name: 'favicon', label: 'Favicon' },
      { id: 9, name: 'itemid', label: 'Стоит "/" в itemid' },
      { id: 10, name: 'logoButton', label: 'Лого - кнопка на рекла' },
      { id: 11, name: 'initJson', label: 'Файл init.json' },
      { id: 15, name: 'lang', label: 'Lang (heading.js + document.js)' },
      { id: 23, name: 'canonical', label: 'Проверить canonical' },
      { id: 24, name: 'adaptive', label: 'Проверка адаптивности' },
    ],
    ampDomain: [
      { id: 1, name: 'redirect', label: 'Редирект с гет параметров на главную страницу' },
      { id: 2, name: 'link', label: 'Трек-ссылка (админка + main.js)' },
      { id: 3, name: 'errorPage', label: '404' },
      { id: 6, name: 'sitemapAmp', label: 'Sitemap (закрыт от индекса)' },
      { id: 7, name: 'robotsAmp', label: 'Robots.txt (закрыт от индекса)' },
      { id: 8, name: 'favicon', label: 'Favicon' },
      { id: 9, name: 'itemid', label: 'Стоит "/" в itemid' },
      { id: 10, name: 'logoButton', label: 'Лого - кнопка на рекла' },
      { id: 11, name: 'initJson', label: 'Файл init.json' },
      { id: 13, name: 'validationAmp', label: 'Amp валидация' },
      { id: 15, name: 'lang', label: 'Lang (heading.js + document.js)' },
      { id: 23, name: 'canonical', label: 'Проверить canonical' },
      { id: 24, name: 'adaptive', label: 'Проверка адаптивности' },
    ],
    pbnDomain: [
      { id: 1, name: 'redirect', label: 'Редирект с гет параметров на главную страницу' },
      { id: 3, name: 'errorPage', label: '404' },
      { id: 4, name: 'sitemap', label: 'Sitemap' },
      { id: 5, name: 'robots', label: 'Robots.txt' },
      { id: 8, name: 'favicon', label: 'Favicon' },
      { id: 9, name: 'itemid', label: 'Стоит "/" в itemid' },
      { id: 10, name: 'logoButton', label: 'Лого - кнопка на рекла' },
      { id: 11, name: 'initJson', label: 'Файл init.json' },
      { id: 12, name: 'validation', label: 'Валидация' },
      { id: 14, name: 'pagespeed', label: 'PageSpeed' },
      { id: 15, name: 'lang', label: 'Lang (heading.js + document.js)' },
      { id: 17, name: 'checkLinkPbn', label: 'Отсутствие ссылки localhost' },
      { id: 18, name: 'checkAmpLinkPbn', label: 'Отсутствие ссылки amphtml' },
      { id: 22, name: 'checkBrands', label: 'Проверка на упоминание в коде других брендов' },
      { id: 23, name: 'canonical', label: 'Проверить canonical' },
      { id: 24, name: 'adaptive', label: 'Проверка адаптивности' },
    ],
  };
function DomainFields({ fields }) {
  return (
    <div id="root" className="tabcontent">
      {fields.map((field) => (
        <div key={field.id}>
          <label htmlFor={field.id} className="control">
            {field.label}
            <input id={field.id} name={field.name} required={true} type="checkbox" />
            <div className="control__indicator"></div>
          </label>
        </div>
      ))}
    </div>
  );
}
  const renderTabContent = () => {
    switch (activeTab) {
      case 'mainDomain':
        return <DomainFields fields={domainFields.mainDomain} />;
      case 'ampDomain':
        return <DomainFields fields={domainFields.ampDomain} />;
      case 'pbnDomain':
        return <DomainFields fields={domainFields.pbnDomain} />;
    }
  };

  return (
    <>
      <Header name={name} />

      <div className="page wrapper2">
        <div className="pageWrapper">
          <div className="pageInner">
            <h2>Next check-list</h2>
            <form method="POST" id="checkListForm" action="/update-check-list">
              <div className="tabs">
                <div
                  className={`tablinks ${activeTab === 'mainDomain' ? 'active' : ''}`}
                  onClick={() => setActiveTab('mainDomain')}
                >
                  Основной домен
                </div>
                <div
                  className={`tablinks ${activeTab === 'ampDomain' ? 'active' : ''}`}
                  onClick={() => setActiveTab('ampDomain')}
                >
                  AMP домен
                </div>
                <div
                  className={`tablinks ${activeTab === 'pbnDomain' ? 'active' : ''}`}
                  onClick={() => setActiveTab('pbnDomain')}
                >
                  PBN
                </div>
              </div>

              <div className="form-wrapper">
                <input id="userName" type="hidden" name="userName" value={name} />
                <input id="type" type="hidden" name="type" value={activeTab} />
                <input
                  id="domenName"
                  className="domenName"
                  type="text"
                  placeholder="Введите домен"
                  name="domenName"
                  required=""
                />
                {renderTabContent()}
                <button className="check-list-btn">Отправить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Checklist;