import "../App.css";
import Header from "../components/Header";
import { useParams, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useAPI } from "../contexts/apiContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AmpRotation from "../components/AmpRotation";
import AmpSwitcher from "../components/AmpSwitcher";

async function getProject(s, n) {
  try {
    const response = await fetch(`http://${s}:7000/projects/one/${n}`);
    if (!response.ok) {
      throw new Error(
        `Ошибка запроса: ${response.status} ${response.statusText}`
      );
    }

    const domain = await response.json();
    return domain.settings;
  } catch (error) {
    console.error("Произошла ошибка при получении данных проекта:", error);
    return null;
  }
}

async function getDuty() {
  try {
    const response = await fetch(`http://212.162.153.44:7501/users/`);

    if (!response.ok) {
      throw new Error(
        `Ошибка запроса: ${response.status} ${response.statusText}`
      );
    }

    const duty = await response.json();
    return Object.values(duty).filter((e) => e.role === "seo");
  } catch (error) {
    console.error("Произошла ошибка при запросе данных:", error);

    return [];
  }
}

function Project() {
  const { domainsE } = useAPI();
  const params = useParams();
  const net = params.net;
  const [domain, setDomain] = useState({});
  const { state } = useLocation();
  const [redirects, setRedirects] = useState([]);
  const [option, setOption] = useState();
  const [canonical, setCanonical] = useState([]);
  const [canonicalOption, setCanonicalOption] = useState();
  const [pageRedirects, setPageRedirects] = useState([]);
  const [pageRedirectOption, setPageRedirectOption] = useState();
  const [reserveOption, setReserveOption] = useState();
  const [duty, setDuty] = useState([]);
  const [seo, setSeo] = useState();
  const [seoSecond, setSecondSeo] = useState();
  const [sitemap, setSitemap] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [cloakDomain1, setCloakdomain1] = useState("");
  const [cloakDomain2, setCloakdomain2] = useState("");
  const [isTaskQueued, setIsTaskQueued] = useState(false);
  const [selectedCloak1, setSelectedCloak1] = useState("");
  const [selectedCloak2, setSelectedCloak2] = useState("");
  const [filter, setFilter] = useState("");
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [cloakDomainsArr, setCloakDomainsArr] = useState([]);
  const [banned, setBanned] = useState([]);

  // состояния для AmpSwitcher и AmpRotation
  const [isAmpProject, setIsAmpProject] = useState(false);
  const [domainsList, setDomainsList] = useState([]);
  const [ampList, setAmpList] = useState([]);
  const [transformedAllDomains, setTransformedAllDomains] = useState([]);
  const [amp, setAmp] = useState("");

  const getServerByNet = useCallback(() => {
    for (const item of domainsE) {
      if (item.net === net) {
        return item.server;
      }
    }
    console.log("Сервер не найден");
    return null;
  }, [domainsE, net]);

  const server = useMemo(
    () => (state ? state.server : getServerByNet()),
    [state, getServerByNet]
  );

  async function showMessage(result) {
    setLoading(true);

    if (result.errors && result.errors.length) {
      Swal.fire({
        icon: "error",
        title: "Ошибка",
        text: result.errors[0],
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Успех!",
        text: result.message,
      });
    }

    setLoading(false);
  }

  async function settingsUpdate(s, f) {
    await fetch(`http://${s}:7000/projects/settings-update`, {
      method: "post",
      body: JSON.stringify({
        ip: s,
        folder: f,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((json) => showMessage(json));
  }

  async function reamp(s, f) {
    await fetch(`http://${s}:7000/projects/update-amp`, {
      method: "post",
      body: JSON.stringify({
        folder: f,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((json) => showMessage(json));
  }

  async function reglue(c) {
    if (domain.reserve == "none") {
      Swal.fire({
        icon: "error",
        title: "Не получилось",
        text: "Резервный домен не установлен",
      });
    } else {
      fetch(`http://212.162.153.44:7501/projects/reglue`, {
        method: "post",
        body: JSON.stringify({
          trigger: "webdev:settings",
          username: "",
          oldCanon: c,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((json) => showMessage(json));
    }
  }

  async function relink(s, f, l) {
    await fetch(`http://${s}:7000/projects/relink`, {
      method: "post",
      body: JSON.stringify({
        folder: f,
        link: l,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((json) => showMessage(json));
  }

  async function remap(s, f) {
    await fetch(`http://${s}:7000/projects/remap`, {
      method: "post",
      body: JSON.stringify({
        name: f,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((json) => showMessage(json));
  }

  async function updateValue(e, server, net) {
    e.preventDefault();
    await fetch(`http://${server}:7000/projects/update-value`, {
      method: "post",
      body: JSON.stringify({
        folder: net,
        pageRedirects: pageRedirects,
        redirects: redirects,
        canonical: canonical,
        ampList: ampList,
        amp: isAmpProject ? amp : false,
        seo: seo == undefined ? domain.duty : seo,
        seoSecond: seoSecond == undefined ? domain.secondDuty : seoSecond,
        reserveOption:
          reserveOption == undefined ? domain.reserve : reserveOption,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((json) => showMessage(json));
  }

  async function updateDomain(server, net) {
    fetch(`http://${server}:7000/projects/update-domain`, {
      method: "post",
      body: JSON.stringify({
        folder: net,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((json) => showMessage(json));
  }

  function redirectChange(url, event) {
    const redirectIndex = redirects.findIndex((r) => r.url == url);
    redirects[redirectIndex].value = event.target.value;
    setRedirects([...redirects]);
  }

  function addRedirect() {
    redirects.find((e) => e.url == option) == undefined
      ? setRedirects([...redirects, { url: option, value: "" }])
      : alert("Такой домен уже существует");
  }

  function removeRedirect(url) {
    setRedirects(redirects.filter((e) => e.url !== url));
  }

  function canonicalChange(url, event) {
    const canonicalIndex = canonical.findIndex((r) => r.url == url);
    canonical[canonicalIndex].value = event.target.value;
    setCanonical([...canonical]);
  }

  function addCanonical() {
    canonical.find((e) => e.url == canonicalOption) == undefined
      ? setCanonical([...canonical, { url: canonicalOption, value: "" }])
      : alert("Такой каноникал уже есть");
  }

  function removeCanonical(url) {
    setCanonical(canonical.filter((e) => e.url !== url));
  }

  function addRedirectDomain() {
    if (pageRedirects && Array.isArray(pageRedirects)) {
      const found = pageRedirects.find((e) => e.domain == pageRedirectOption);
      if (found === undefined) {
        setPageRedirects([
          ...pageRedirects,
          { domain: pageRedirectOption, redirects: [{ url: "", value: "" }] },
        ]);
      } else {
        alert("Такой домен уже существует");
      }
    } else {
      console.error("pageRedirects is undefined or not an array");
    }
  }

  function addPageRedirect(domain) {
    const domainObj = pageRedirects.find((r) => r.domain == domain);
    domainObj.redirects.push({ url: "", value: "" });
    setPageRedirects([...pageRedirects]);
  }

  function redirectPageChange(domain, index, event) {
    const domainObject = pageRedirects.find((r) => r.domain == domain);
    domainObject.redirects[index].url = event.target.value;
    setPageRedirects([...pageRedirects]);
  }

  function redirectValueChange(domain, index, event) {
    const domainObject = pageRedirects.find((r) => r.domain == domain);
    domainObject.redirects[index].value = event.target.value;
    setPageRedirects([...pageRedirects]);
  }

  function removeRedirectDomain(domain) {
    setPageRedirects(pageRedirects.filter((e) => e.domain !== domain));
  }

  function removePageRedirect(domain, index) {
    const domainObject = pageRedirects.find((r) => r.domain == domain);
    domainObject.redirects.splice(index, 1);
    setPageRedirects([...pageRedirects]);
  }

  const handleSwitch = () => {
    setIsOn(!isOn);
  };
  const handleDomains = (e) => {
    switch (e) {
      case "Основной домен":
        setCloakDomainsArr([{ url: domain.canon, cloak: "" }]);
        break;
      case "Cеточные":
        setCloakDomainsArr(
          domain.domains
            .filter((e) => e != domain.canon)
            .map((e) => (e = { url: e, cloak: "" }))
        );
        break;
      case "Текущий амп":
        setCloakDomainsArr([{ url: domain.amp, cloak: "" }]);
        break;
      case "Незабаненные ампы":
        if (Array.isArray(domain.ampDomains) && domain.ampDomains.length) {
          setCloakDomainsArr(
            domain.ampDomains
              .filter((x) => !banned.banned.includes(x))
              .map((e) => (e = { url: e, cloak: "" }))
          );
        } else {
          setCloakDomainsArr([{ url: "тут нет доменов", cloak: "" }]);
        }
        break;
      case "Забаненные ампы":
        if (Array.isArray(domain.ampDomains) && domain.ampDomains.length) {
          setCloakDomainsArr(
            domain.ampDomains
              .filter((x) => banned.banned.includes(x))
              .map((e) => (e = { url: e, cloak: "" }))
          );
        } else {
          setCloakDomainsArr([{ url: "тут нет доменов", cloak: "" }]);
        }
        break;
      case "Вся сетка":
        setCloakDomainsArr(
          domain.domains.map((e) => (e = { url: e, cloak: "" }))
        );
        break;
      default:
        console.log(`err`);
    }
  };
  const handleType = (e) => {
    const newArr = cloakDomainsArr.map((domain) => {
      return {
        ...domain,
        cloak: e,
      };
    });
    setCloakDomainsArr(newArr);
  };
  const handleTypeOne = (e, url) => {
    const newArr = cloakDomainsArr.map((domain) => {
      if (domain.url != url) {
        console.log("domain", domain);
        return domain;
      } else {
        return {
          ...domain,
          cloak: e,
        };
      }
    });
    setCloakDomainsArr(newArr);
  };
  const removeCloakDomain = (url) => {
    setCloakDomainsArr(cloakDomainsArr.filter((e) => e.url !== url));
  };
  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);

    const filtered = domain.domains.filter((domain) => {
      if (domain) {
        return domain.toLowerCase().includes(value);
      }
      return false;
    });

    setCloakDomainsArr(filtered.map((e) => (e = { url: e, cloak: "" })));
  };
  const sendCloak = async (cloakDomainsArr) => {
    await fetch("https://satellite.alfasearch.ru/api/v1/auth/login", {
      method: "post",
      body: JSON.stringify({
        login: "webdev-tech@alfaleads.net",
        password: "emaSYf7^*Cu^2!M8nMxl",
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then(
        async (data) =>
          await fetch("https://satellite.alfasearch.ru/api/v1/groups/", {
            method: "post",
            body: JSON.stringify({
              scripts: [
                {
                  index: 0,
                  script_id: "25981b3c-6f97-4b2a-b3a4-4d849224c959",
                  dependency: null,
                  tasks: cloakDomainsArr.map(
                    (e, index) =>
                      (e = {
                        index: index,
                        server_id: null,
                        args: { domain: e.url, waf_rule: e.cloak },
                      })
                  ),
                },
              ],
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.access_token}`,
            },
          })
            .then((response) => response.json())
            .then((info) => {
              if (info.tasks) {
                setIsTaskQueued(true);
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Ошибка",
                  text: "что-то пошло не так",
                });
              }
            })
      );
  };
  const handleTaskQueued = async (
    canon,
    canonValue,
    prevCanon,
    prevCanonValue
  ) => {
    // Запрос напрямую
    await fetch("https://satellite.alfasearch.ru/api/v1/auth/login", {
      method: "post",
      body: JSON.stringify({
        login: "webdev-tech@alfaleads.net",
        password: "emaSYf7^*Cu^2!M8nMxl",
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then(
        async (data) =>
          await fetch("https://satellite.alfasearch.ru/api/v1/groups/", {
            method: "post",
            body: JSON.stringify({
              scripts: [
                {
                  index: 0,
                  script_id: "25981b3c-6f97-4b2a-b3a4-4d849224c959",
                  dependency: null,
                  tasks: [
                    {
                      index: 0,
                      server_id: null,
                      args: { domain: canon, waf_rule: canonValue },
                    },
                    {
                      index: 1,
                      server_id: null,
                      args: { domain: prevCanon, waf_rule: prevCanonValue },
                    },
                  ],
                },
              ],
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.access_token}`,
            },
          })
            .then((response) => response.json())
            .then((info) => {
              if (info.tasks) {
                setIsTaskQueued(true);
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Ошибка",
                  text: "что-то пошло не так",
                });
              }
            })
      );
  };

  const domainsType = [
    "Основной домен",
    "Cеточные",
    "Текущий амп",
    "Незабаненные ампы",
    "Забаненные ампы",
    "Вся сетка",
  ];

  const cloakOptions = [
    "CF05-AMP",
    "CF05-WEB",
    "CF05UA-AMP",
    "CF05TR-AMP",
    "CF05TR-WEB",
    "CF05RU-WEB",
    "CF05A-WEB",
    "CF05GT-WEB",
    "CF05GT-AMP",
    "CF05B-WEB",
    "CF05-SUB01",
    "CF05-SUB02",
    "CF07-WEB",
    "CF07A-WEB",
    "CF07-BOTS",
    "CF07A-BOTS",
  ];
  // загрузка данных
  useEffect(() => {
    const fetchData = async () => {
      const dutyArr = await getDuty();
      const domainData = await getProject(server, net);
      const bannedResponse = await fetch(
        "https://webdev-alfasearch.ru/banned/"
      );
      const bannedDomains = await bannedResponse.json();
      setBanned(bannedDomains);
      setDomain(domainData);
      setRedirects(domainData.redirects);
      setDuty(dutyArr);
      setSitemap(domainData.sitemapType);
      setPageRedirects(domainData.pageRedirects);
      setCanonical(domainData.canonicalList);
      setAmpList(domainData.ampDomains || []);
      setDomainsList(domainData.domains);
      setIsAmpProject(Boolean(domainData.amp));
      setAmp(domainData.amp);
    };
    fetchData().catch("fetch error", console.error);
  }, []);

  //обработчики для AmpSwitcher
  const handleAmpSwitch = useCallback(() => {
    setIsAmpProject((prevState) => !prevState);
  }, []);

  const handleAddToAmpList = useCallback(({ name }) => {
    setTransformedAllDomains((prevgeneralArray) => {
      const index = prevgeneralArray.findIndex((item) => item.name === name);
      if (index !== -1) {
        const itemToMove = prevgeneralArray[index];
        setAmpList((prevAmpArray) => {
          const updatedAmpArray = [...prevAmpArray, itemToMove.name];
          updatedAmpArray.sort();
          return updatedAmpArray;
        });
        const updatedArray = [...prevgeneralArray];
        updatedArray[index] = { ...itemToMove, isAmp: true };
        return updatedArray;
      }
      return prevgeneralArray;
    });
  });
  const handleRemoveAmp = useCallback(
    (name) => {
      setTransformedAllDomains((prev) =>
        prev.map((item) =>
          item.name === name ? { ...item, isAmp: false } : item
        )
      );
      setAmpList((prevAmpList) =>
        prevAmpList.filter((ampName) => ampName !== name)
      );
    },
    [setAmpList]
  );
  const transformDomainsIntoObjects = useCallback(() => {
    const domainMap = new Map(
      domainsList.map((domain) => [domain, { name: domain }])
    );
    return domainsList.map((domain) => ({
      ...domainMap.get(domain),
      isAmp: ampList.includes(domain),
    }));
  }, [domainsList, ampList]);

  useEffect(() => {
    setTransformedAllDomains(transformDomainsIntoObjects());
  }, [transformDomainsIntoObjects]);

  const handleResetAmpList = () => {
    Swal.fire({
      title: "Вы уверены?",
      text: "Все AMP-домены будут удалены из настроек!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Да, удалить",
      cancelButtonText: "Отмена",
    }).then((result) => {
      if (result.isConfirmed) {
        setAmpList([]);
        Swal.fire("Удалено!", "AMP-домены успешно удалены.", "success");
      }
    });
  };

  const handleUpdateMainAmp = (domain) => {
    if (amp) {
      Swal.fire({
        title: "Заменить главный AMP-домен?",
        text: "Перед заменой убедитесь, что новый домен доступен и не заблокирован.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Продолжить",
        cancelButtonText: "Отмена",
      }).then((result) => {
        if (result.isConfirmed) {
          setAmp(domain);
          Swal.fire("Успешно!", "Главный домен выбран.", "success");
        }
      });
    } else {
      setAmp(domain);
    }
  };

  const isAmpGeo = ["ru", "tr", "az", "kz"].includes(
    domain.geo?.split("-")[0]?.toLowerCase()
  );
  
  return (
    <>
      <Header />

      <div class="settings_page settingsChange page wrapper">
        <div class="settings_pageWrapper pageWrapper">
          <div class="pageInner">
            <form method="POST" id="settingsForm" autocomplete="off">
              <h2>Настройки {domain.canon}</h2>
              <div class="settingsList">
                <div class="info">
                  <p>Текущие данные</p>
                  <div class="item net">
                    <p class="key">Сетка</p>
                    <span class="divider"></span>
                    <p class="value">{net}</p>
                  </div>
                  <div class="item net">
                    <p class="key">Гео</p>
                    <span class="divider"></span>
                    <p class="value">{domain.geo}</p>
                  </div>
                  <div class="item net">
                    <p class="key">Бренд</p>
                    <span class="divider"></span>
                    <p class="value">{domain.brand}</p>
                  </div>
                  <div class="item prev">
                    <p class="key">Предыдущий web-домен</p>
                    <span class="divider"></span>
                    <p class="value">{domain.prev}</p>
                  </div>
                  <div class="item canon">
                    <p class="key">Текущий web-домен</p>
                    <span class="divider"></span>
                    <p id="canonical" class="value">
                      {domain.canon}
                    </p>
                  </div>
                  <div class="item amp">
                    <p class="key">Amp-домен</p>
                    <span class="divider"></span>
                    <p id="amp" class="value">
                      {domain.amp}
                    </p>
                  </div>
                  <div class="item prevAmp">
                    <p class="key">Предыдущий amp-домен</p>
                    <span class="divider"></span>
                    <p class="value">{domain.prevAmp}</p>
                  </div>
                  <div class="item reserve">
                    <p class="key">Резервный web-домен</p>
                    <span class="divider"></span>
                    <p class="value">{domain.reserve}</p>
                  </div>
                  <div class="item sitemap">
                    <p class="key">Sitemap</p>
                    <span class="divider"></span>
                    {sitemap == "self" ? (
                      <p class="value">
                        в сайтмапе у всех доменов сетки указан сам домен
                      </p>
                    ) : (
                      <p class="value">
                        в сайтмапе у всех доменов сетки указан main-домен
                      </p>
                    )}
                  </div>
                  <div class="item track">
                    <p class="key">Трек-ссылка</p>
                    <span class="divider"></span>
                    <p class="value">{domain.trackLink}</p>
                  </div>
                  <div class="item duty">
                    <p class="key">Ответственный</p>
                    <span class="divider"></span>
                    <p class="value">{domain.duty}</p>
                  </div>
                  <div class="item duty">
                    <p class="key">Второй ответственный</p>
                    <span class="divider"></span>
                    <p class="value">{domain.secondDuty}</p>
                  </div>
                </div>
                <div class="change">
                  <p>Внести изменения</p>
                  <div class="inline">
                    <span>Заменить резервный web-домен</span>
                    <div class="selectWrapper">
                      {domain.domains ? (
                        <select
                          onChange={(e) => setReserveOption(e.target.value)}
                          name="chooseDomain"
                          id="chooseDomain"
                        >
                          <option value="none" selected="">
                            {domain.reserve !== "none"
                              ? domain.reserve
                              : "Не выбрано"}
                          </option>
                          {domain.domains.map((e, index) => (
                            <option className="full" key={index} value={e}>
                              {e}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                  </div>
                  <div className="inline">
                    <span>Заменить ответственного</span>
                    <div className="selectWrapper">
                      {duty ? (
                        <select
                          onChange={(e) => setSeo(e.target.value)}
                          name="duty"
                          id="duty"
                        >
                          <option value="none" selected="">
                            {domain.duty != "none" ? domain.duty : "Не выбрано"}
                          </option>
                          {duty.map((e, index) => (
                            <option className="full" key={index} value={e.name}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                  </div>
                  <div className="inline">
                    <span>Заменить второго ответственного</span>
                    <div className="selectWrapper">
                      {duty ? (
                        <select
                          onChange={(e) => setSecondSeo(e.target.value)}
                          name="duty"
                          id="duty"
                        >
                          <option value="none" selected="">
                            {domain.secondDuty == undefined
                              ? "Не выбрано"
                              : domain.secondDuty != "none"
                              ? domain.secondDuty
                              : "Не выбрано"}
                          </option>
                          {duty
                            .filter((e) => e.name != domain.duty)
                            .map((e, index) => (
                              <option
                                className="full"
                                key={index}
                                value={e.name}
                              >
                                {e.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                  </div>
                  <label className="inline nobg">
                    <p>
                      Заменить старый web-домен в кастомных редиректах на новый
                    </p>
                    <div
                      className="rerender"
                      onClick={async () => await updateDomain(server, net)}
                    >
                      Заменить домен
                    </div>
                  </label>

                  <label className="inline nobg">
                    <p>Инициировать переклей на резервный web-домен</p>
                    <div
                      onClick={async () => await reglue(domain.canon)}
                      className="reglue"
                    >
                      Переклеить
                    </div>
                  </label>

                  <label className="inline nobg">
                    <p>Инициировать смену amp-домена</p>
                    <div
                      onClick={async () => await reamp(server, net)}
                      className="reamp"
                    >
                      Заменить amp
                    </div>
                  </label>

                  <label className="inline nobg">
                    <p>Поменять тип sitemap</p>
                    <div
                      onClick={async () => await remap(server, net)}
                      className="remap-cur"
                    >
                      Поменять тип sitemap
                    </div>
                  </label>

                  <label className="inline nobg">
                    <p>Заменить трек-ссылку</p>
                    <input
                      type="text"
                      onChange={(e) => setLink(e.target.value)}
                      name="newTrackDomain"
                      id="newTrackDomain"
                      placeholder="Введите новую трек-ссылку"
                    />
                    <div
                      onClick={async () => await relink(server, net, link)}
                      className="relink"
                    >
                      Заменить
                    </div>
                  </label>

                  <label className="inline nobg">
                    <p>Актуализировать сетку</p>
                    <div
                      onClick={async () => await settingsUpdate(server, net)}
                      className="changeLog"
                    >
                      Добавить домены
                    </div>
                  </label>

                  {loading && (
                    <div className="loader">
                      <span className="loading-text"></span>
                    </div>
                  )}
                </div>
              </div>
              <div class="cloak-block">
                <h2>Замена клоаки:</h2>
                <div class="settingsList list">
                  <div class="addType">
                    <div class="selectWrapper selectWrapper--3">
                      {domainsType ? (
                        <select
                          onChange={(e) => handleDomains(e.target.value)}
                          name="chooseType"
                          id="chooseType"
                        >
                          <option value="none">Тип домена</option>
                          {domainsType.map((e, index) => (
                            <option key={index} className="full">
                              {e}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                    <div class="selectWrapper selectWrapper--3">
                      {cloakOptions ? (
                        <select
                          onChange={(e) => handleType(e.target.value)}
                          name="chooseType"
                          id="chooseType"
                        >
                          <option value="none">Тип клоаки</option>
                          {cloakOptions.map((e, index) => (
                            <option key={index} className="full">
                              {e}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                    <div class="filter">
                      <input
                        type="text"
                        value={filter}
                        onChange={handleFilterChange}
                        placeholder="Фильтр по домену"
                        className="filterInput"
                      />
                    </div>
                  </div>
                  <div class="cloakDomainsArr adding">
                    <h2>Список доменов</h2>
                    {cloakDomainsArr &&
                      cloakDomainsArr.map((e, index) => (
                        <>
                          <div class="row" id={index}>
                            <input class="name" readonly="true" value={e.url} />
                            {cloakOptions ? (
                              <select
                                onChange={(el) =>
                                  handleTypeOne(el.target.value, e.url)
                                }
                                name="chooseType"
                                id="chooseType"
                                value={e.cloak}
                              >
                                <option value="none">Тип клоаки</option>
                                {cloakOptions.map((e, index) => (
                                  <option key={index} className="full">
                                    {e}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <p>Loading...</p>
                            )}
                            <div
                              onClick={(event) => removeCloakDomain(e.url)}
                              class="deleteButton"
                            ></div>
                          </div>
                        </>
                      ))}
                    <div className="cloak-active-btn">
                      <div
                        className="cloak-active-btn-button"
                        onClick={() => sendCloak(cloakDomainsArr)}
                      >
                        Поменять клоаку
                      </div>
                      {isTaskQueued && (
                        <p>
                          Твоя таска на замену клоаки отправлена в очередь задач
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom">
                <h2>Кастомные редиректы:</h2>
                <div class="settingsList list">
                  <div class="addDomain">
                    <div class="selectWrapper selectWrapper--2">
                      {domain.domains ? (
                        <select
                          onChange={(e) => setOption(e.target.value)}
                          name="chooseDomain"
                          id="chooseDomain"
                        >
                          <option value="none">Выберите домен</option>
                          {domain.domains.map((e, index) => (
                            <option key={index} className="full">
                              {e}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                    <div onClick={addRedirect} class="add">
                      Добавить домен
                    </div>
                  </div>
                  <div class="redirectsBlock adding">
                    {redirects &&
                      redirects.map((e, index) => (
                        <div class="row" id={index}>
                          <input class="name" readonly="true" value={e.url} />
                          <input
                            class="domainRedirect"
                            value={e.value}
                            onChange={(event) => redirectChange(e.url, event)}
                            type="text"
                          />
                          <div
                            onClick={(event) => removeRedirect(e.url)}
                            class="deleteButton"
                          ></div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div class="customPageRedirect">
                <h2>Настройка постраничных редиректов</h2>
                <div class="settingsList list">
                  <div class="addCustomCanonicalDomain">
                    <div class="selectWrapper selectWrapper--2">
                      {domain.domains ? (
                        <select
                          onChange={(e) =>
                            setPageRedirectOption(e.target.value)
                          }
                          name="chooseCanonDomain"
                          id="chooseCanonDomain"
                        >
                          <option value="none">Выберите домен</option>
                          {domain.domains
                            .filter(
                              (x) =>
                                ![...redirects].map((e) => e.url).includes(x)
                            )
                            .map((e, index) => (
                              <option key={index} className="full">
                                {e}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                    <div class="PageRedirect" onClick={addRedirectDomain}>
                      Добавить
                    </div>
                  </div>
                  <div class="PageRedirectBlock adding">
                    {pageRedirects &&
                      pageRedirects.map((url, index) => (
                        <div class="redirectPageBlock">
                          <div class="titleWrap">
                            <h3>{url.domain}</h3>
                            <div
                              onClick={(event) =>
                                removeRedirectDomain(url.domain)
                              }
                              class="removeElemButton"
                            ></div>
                          </div>
                          <div class="subtitle">
                            <div class="subtitle-item">
                              <h4>Откуда</h4>
                              <p>(без http:// и https://)</p>
                            </div>
                            <div class="subtitle-item">
                              <h4>Куда</h4>
                              <p>(с http:// или https://)</p>
                            </div>
                          </div>
                          {url.redirects.map((e, idx) => (
                            <div class="row">
                              <div class="row-item">
                                <input
                                  class="name"
                                  value={e.url}
                                  onChange={(event) =>
                                    redirectPageChange(url.domain, idx, event)
                                  }
                                />
                              </div>

                              <div class="row-item">
                                <input
                                  class="domainRedirect"
                                  value={e.value}
                                  onChange={(event) =>
                                    redirectValueChange(url.domain, idx, event)
                                  }
                                  type="text"
                                />
                              </div>

                              <div class="row-item">
                                <div
                                  onClick={(event) =>
                                    removePageRedirect(url.domain, idx)
                                  }
                                  class="deleteButton"
                                ></div>
                              </div>
                            </div>
                          ))}

                          <div
                            onClick={(e) => addPageRedirect(url.domain)}
                            class="addRedirectButton"
                          >
                            Добавить редирект
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {isAmpGeo && (
                <>
                  {domainsList.length > 0 && (
                    <DndProvider backend={HTML5Backend}>
                      <AmpSwitcher
                        amp={amp}
                        onRemoveAmp={handleRemoveAmp}
                        onAddToAmpList={handleAddToAmpList}
                        transformedAllDomains={transformedAllDomains}
                        onAmpSwitch={handleAmpSwitch}
                        ampList={ampList}
                        isAmpProject={isAmpProject}
                        onResetAmpList={handleResetAmpList}
                        onUpdateMainAmp={handleUpdateMainAmp}
                      />
                    </DndProvider>
                  )}
                  {isAmpProject &&
                  <AmpRotation net={net} />}
                </>
              )}
              <div class="form_button">
                <button
                  onClick={(e) => updateValue(e, server, net)}
                  class="updateData"
                  type="submit"
                >
                  Обновить данные
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Project;
