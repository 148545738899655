import React from 'react';
import '../App.css';
import { useUser } from '../contexts/userContext';

function Header() {
const {username, userImage} = useUser()
  
  return (
    <>
      <header className="header">
        <div className="headerWrapper wrapper">
          <div className="logo">
            <img src="/img/logo.png" alt="logo" />
          </div>
          <div className="menu">
            <a href="/projects">Настройки сеток</a>
            <a href="/index-page">Индексация URL’ов</a>
          </div>
          <div className="user">
            <div className="userName">
              <span>Привет, {username ? username : 'Гость'}</span>
              <span>Чем займемся сегодня?</span>
            </div>
            <div className="userImg">
             <img src={userImage} alt="user logo" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;

