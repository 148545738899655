import React, {useState} from "react";
import { useDrop } from "react-dnd";
import DraggableElement from "./DraggableElement";

const AmpDomainsList = ({
  onAddToAmpList,
  filteredList,
  onRemoveAmp,
  isAmpProject,
  amp,
  onUpdateMainAmp,
  searchQuery,
}) => {
  const [showAll, setShowAll] = useState(false);
  const MAX_VISIBLE_DOMAINS = 7;

  const AMP_DOMAINS_CLASSES = { button: "delete-amp-button" };
  const [, dropRef] = useDrop({
    accept: "domains",
    drop(item) {
      onAddToAmpList(item);
    },
  });

  const [, dropMainRef] = useDrop({
    accept: "mainAmp",
    drop(item) {
      onUpdateMainAmp(item.name);
    },
  });
  const dndBoxClassName = !isAmpProject ? "dnd-box faded" : filteredList.length <= MAX_VISIBLE_DOMAINS ? "dnd-box drop-area" : "dnd-box"

  const displayedList = showAll ? filteredList : filteredList.slice(0, MAX_VISIBLE_DOMAINS);

  return (<div className={dndBoxClassName}>
    <div className="amp-main">
      <h3>Главный домен в AMP-сетке</h3>
      <ul ref={dropMainRef}>
        {isAmpProject && amp ? (
          <li className="list-item amp">{amp}</li>
        ) : isAmpProject ? (
          <p>Перетащите сюда домен из списка ниже.</p>
        ) : (
          <p>AMP-сетка отключена.</p>
        )}
      </ul>
    </div>
    <div className="amp-list" ref={dropRef}>
      <h3>AMP-сетка</h3>
      <ul>
        {displayedList && displayedList.length > 0 ? (
          displayedList.map((domain) => {
            const isMainAmp = domain === amp;

            return isAmpProject ? (
              <DraggableElement
                key={domain}
                domain={domain}
                dragType="mainAmp"
                customClass={AMP_DOMAINS_CLASSES}
                onAction={onRemoveAmp}
              >
                {isMainAmp ? (
                  <img
                    src="/img/main-amp-icon.svg"
                    alt="Иконка главного Amp в сетке"
                  />
                ) : (
                  <button
                    type="button"
                    className="make-main-button"
                    onClick={() => {
                      onUpdateMainAmp(domain);
                    }}
                  ></button>
                )}
              </DraggableElement>
            ) : (
              <li key={domain} className="list-item" id={domain}>
                {domain}
              </li>
            );
          })
        ) : (
            <p>
              {isAmpProject && searchQuery
                ? "Ничего не найдено."
                : isAmpProject
                  ? "Список пуст. Добавьте домены из левого столбца для настройки AMP-сетки."
                  : "Чтобы настроить AMP-сетку, переключите тумблер справа."}
            </p>
        )}
      </ul>
      {filteredList.length > MAX_VISIBLE_DOMAINS && (
        <button type="button" onClick={() => setShowAll(!showAll)} className="show-more-button">
          {showAll ? "Скрыть" : "Показать все"}
        </button>
      )}
    </div>
  </div>
  );
};

export default AmpDomainsList;
