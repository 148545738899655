import React, { useState } from "react";
import ToggleAmpCheckbox from "./ToggleAmpCheckbox";
import AllDomainsList from "./AllDomainsList";
import AmpDomainsList from "./AmpDomainsList";
import SearchQuery from "./SearchQuery";

function AmpSwitcher({
  onAmpSwitch,
  amp,
  isAmpProject,
  onRemoveAmp,
  onAddToAmpList,
  transformedAllDomains,
  onResetAmpList,
  onUpdateMainAmp,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const filterAndSortList = (list, query) => {
    return list
      .filter((item) => {
        const name = typeof item === "string" ? item : item.name;
        return name.toLowerCase().includes(query.toLowerCase());
      })
      .sort((a, b) => {
        const nameA = typeof a === "string" ? a : a.name;
        const nameB = typeof b === "string" ? b : b.name;
        return nameA.localeCompare(nameB);
      });
  };

  const filteredGeneralList = filterAndSortList(
    transformedAllDomains,
    searchQuery
  );

  const filteredAmpList = filterAndSortList(
    transformedAllDomains
      .filter((domain) => domain.isAmp)
      .map((domain) => domain.name),
    searchQuery
  );

  const handleCheckboxChange = () => {
    onAmpSwitch();
  };

  const handleSearchQueryChange = (e) => setSearchQuery(e.target.value);

  const clearSearch = () => setSearchQuery("");

  return (
    <section className="section ampSwitcher">
      <h2>Настройки AMP-сетки:</h2>
      <div className="settingsList">
        {transformedAllDomains.length > 10 && (
          <SearchQuery
            onSearchQueryChange={handleSearchQueryChange}
            clearSearch={clearSearch}
          />
        )}
        <div className="flex">
          <AllDomainsList
            filteredList={filteredGeneralList}
            onAddToAmpList={onAddToAmpList}
            isAmpProject={isAmpProject}
          />
          <AmpDomainsList
            searchQuery={searchQuery}
            isAmpProject={isAmpProject}
            amp={amp}
            filteredList={filteredAmpList}
            onAddToAmpList={onAddToAmpList}
            onRemoveAmp={onRemoveAmp}
            onUpdateMainAmp={onUpdateMainAmp}
          />
          <div>
            <ToggleAmpCheckbox
              isChecked={isAmpProject}
              handleInput={handleCheckboxChange}
            />
            {filteredAmpList.length > 0 && (
              <button
                type="button"
                onClick={onResetAmpList}
                id="reset-btn"
                class="reset-button"
              >
                Очистить список
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AmpSwitcher;
