import React, { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import CountryFlag from "react-country-flag";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import Swal from "sweetalert2";
import { useAPI } from "../contexts/apiContext";
import Loader from "../components/Loader";

const getNotBanned = (domainsList, bannedList) => {
  if (Array.isArray(domainsList) && domainsList.length) {
    return domainsList.filter((x) => !bannedList.banned.includes(x));
  }
  return [];
};

const showNotBanned = (e, canon, list) => {
  e.stopPropagation();
  e.preventDefault();
  if (e.currentTarget != e.target) return;

  Swal.fire({
    title: `Список незабаненных ампов для ${canon}`,
    text: `${list.join(", ")}`,
  });
};

const downloadCSV = async (e, data) => {
  const request = await fetch(`http://212.162.153.44:7501/file/csv`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(data),
  });
  const response = await request.json();

  if (response.success) {
    Swal.fire({
      icon: "success",
      title: `Выгрузка создана`,
      html: `<a href="/csv/${response.name}.csv" target="_blank">Скачать таблицу</a>`,
    });
  }
};

function Projects({ name }) {
  function normalizeCountryCode(countryCode) {
    
    if (!countryCode) return "RU";
    const [country, region] = countryCode.split("-");

    if (region === "UK") return "GB";

    if (region) return region.toUpperCase();

    if (country === "en") return "GB";
    if (country === "da") return "DK";

    return country.toUpperCase();
  }

  const { domainsE } = useAPI();
  const [banned, setBanned] = useState([]);
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchData = async () => {
      const bannedResponse = await fetch(
        "https://webdev-alfasearch.ru/banned/"
      );
      const bannedDomains = await bannedResponse.json();
      setFilteredDomains(domainsE);
      setBanned(bannedDomains);
      setLoading(false);
    };
    fetchData().catch("fetch error", console.error);
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);

    const filtered = domainsE.filter((domain) => {
      if (domain.name && domain.net) {
        return (
          domain.name.toLowerCase().includes(value) ||
          domain.net.toLowerCase().includes(value)
        );
      }
      return false;
    });

    setFilteredDomains(filtered);
  };

  const handleSort = (field) => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newSortOrder);

    if (field == "ampDomains") {
      const sortedDomains = [...filteredDomains].sort((a, b) => {
        if (a[field] == undefined) {
          a[field] = [];
        }

        if (b[field] == undefined) {
          b[field] = [];
        }

        const notBannedA = getNotBanned(a[field], banned);
        const notBannedB = getNotBanned(b[field], banned);

        if (newSortOrder === "asc") {
          return notBannedA.length - notBannedB.length;
        } else {
          return notBannedB.length - notBannedA.length;
        }
      });
      console.log(sortedDomains);
      setFilteredDomains(sortedDomains);
    } else {
      const sortedDomains = [...filteredDomains].sort((a, b) => {
        const valueA = a[field] ? a[field].toString().toLowerCase() : "";
        const valueB = b[field] ? b[field].toString().toLowerCase() : "";

        if (newSortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });

      setFilteredDomains(sortedDomains);
    }
  };

  const getSortIcon = (field) => {
    if (sortField === field) {
      return sortOrder === "asc" ? (
        <ChevronUpIcon className="sort-icon" />
      ) : (
        <ChevronDownIcon className="sort-icon" />
      );
    }
    return <ChevronDownIcon className="sort-icon-down" />;
  };

  return (
    <>
        <div className="settings_pageWrapper pageWrapper wrapper">
          <Header name={name} />
          <div className="settings_page page wrapper">
            <div className="settings_pageWrapper pageWrapper">
              <div className="pageInner">
                <form>
                  <h2>Настройка сеток</h2>
                  <p>Выберите домен, для которого хотите изменить настройки</p>
                  <div
                    className="downloadCSV"
                    onClick={(e) => downloadCSV(e, filteredDomains)}
                  >
                    <img src="/img/csv.svg" alt="" />
                    Запросить данные в csv
                  </div>
                  <input
                    type="text"
                    value={filter}
                    onChange={handleFilterChange}
                    placeholder="Фильтр по домену или сетке"
                    className="filterInput"
                  />

                  <div className="settingsList">
                    <div className="settingsListTop">
                      <span onClick={() => handleSort("name")}>
                        Домен {getSortIcon("name")}{" "}
                      </span>
                      <span className="span-geo">Гео</span>
                      <span onClick={() => handleSort("brand")}>
                        Бренд {getSortIcon("brand")}
                      </span>
                      <span onClick={() => handleSort("net")}>
                        Сетка {getSortIcon("net")}
                      </span>
                      <span
                        onClick={() => handleSort("ampDomains")}
                        className="amp-list"
                      >
                        Живые ампы {getSortIcon("ampDomains")}
                      </span>
                    </div>
                    {filteredDomains.map((project, index) => {
                      const notBanned = getNotBanned(
                        project.ampDomains,
                        banned
                      );

                      return (
                        <div className="settingsItems" key={index}>
                          <Link
                            to={`/projects/${project.net}/`}
                            state={{ server: project.server }}
                            className="settingsItem"
                          >
                            <span>{project.name}</span>
                            <span>
                              <CountryFlag
                                aria-label={project.geo}
                                countryCode={normalizeCountryCode(project.geo)}
                                svg
                              />
                            </span>
                            <span>
                              {project.brand ? project.brand.toLowerCase() : ""}
                            </span>
                            <span>{project.net}</span>

                            {project.amp ? (
                              project.amp == project.canon ? (
                                <span>Внутренний амп</span>
                              ) : (
                                <span
                                  className={
                                    notBanned.length < 3 ? "danger" : "normal"
                                  }
                                >
                                  <i>{notBanned.length}</i>
                                  {notBanned.length > 0 && (
                                    <b
                                      onClick={(e) =>
                                        showNotBanned(
                                          e,
                                          project.name,
                                          notBanned
                                        )
                                      }
                                    >
                                      посмотреть
                                    </b>
                                  )}
                                </span>
                              )
                            ) : (
                              <span>---</span>
                            )}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default Projects;
