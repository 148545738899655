import React, { createContext, useState, useEffect, useContext } from 'react';
import Cookies from 'universal-cookie';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const cookies = new Cookies();

  useEffect(() => {
    const savedUsername = cookies.get('username');
    if (savedUsername) {
      setUsername(savedUsername);
    }
  }, []);

  const userImage = username
    ? `/img/users/${username}.jpeg`
    : '/img/defaultUser.jpeg';

  return (
    <UserContext.Provider value={{ username, setUsername, userImage }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
