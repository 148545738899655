import React, { useState } from 'react';
import Swal from 'sweetalert2';
import '../App.css';
import Header from '../components/Header';
import { useUser } from '../contexts/userContext';

function IndexPage({ name }) {
  const [domains, setDomains] = useState('');
const {username} = useUser()
  const notification = () => {
    Swal.fire({
      text: 'Подождите несколько минут ответа от индексаторов :)',
    });
  };

  // const indexingDomains = async () => {
  //   const formattedDomains = domains
  //     .replaceAll('https://', '')
  //     .split('\n')
  //     .filter((e) => e.trim() !== '')
  //     .map((e) => e.trim().endsWith('/') ? e.trim().slice(0, -1) : e.trim());

  //   try {
      
  //     const speedyResponse = await fetch('https://api.speedyindex.com/v2/task/google/indexer/create', {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         title: 'test',
  //         urls: formattedDomains.map(domain => `https://${domain}`),
  //       }),
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': '9375dee5b9e9a3664004a2d78aba2342',
  //       },
  //     });

  //     const speedyData = await speedyResponse.json();

  //     if (speedyData.code !== 0) {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Ошибка',
  //         text: 'Что-то пошло не так при обращении к SpeedyIndex',
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'SpeedyIndex',
  //         text: `ID задачи: ${speedyData.task_id}. Индексация прошла успешно!`,
  //       });
  //     }

  //     const linkResponse = await fetch('https://link-indexing-bot.ru/api/tasks/new', {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         user_id: '130428217',
  //         api_key: '21cad721d2f09bdd854f475efe31a6555bca98ccb87e7a6f4fda77d8865e0dfb',
  //         links: formattedDomains.map(domain => `https://${domain}`).join('\n'),
  //         searchengine: 'google',
  //         se_type: 'normal',
  //       }),
  //       headers: { 'Content-Type': 'application/json' },
  //     });

  //     const linkData = await linkResponse.json();

  //     if (linkData.status !== 201) {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Ошибка',
  //         text: linkData.message,
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Link Indexing',
  //         text: `ID задачи: ${linkData.data.task_id}. Задача создана успешно!`,
  //       });
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Ошибка сети',
  //       text: 'Не удалось подключиться к API.',
  //     });
  //   }
  // };


  const indexingDomains = async() => {

    const formattedDomains = domains
        .replaceAll('https://', '')
        .split('\n')
        .filter((e) => e.trim() !== '')
        .map((e) => e.trim().endsWith('/') ? e.trim().slice(0, -1) : e.trim());
  
      await fetch('https://webdev-alfasearch.ru/index-domains/speedy/', {
        method: 'post',
        body: JSON.stringify({
          urls: formattedDomains,
          user: username
        }),
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => response.json())
      .then((data) => {
          
  
          if (data.error) {
            Swal.fire({
              code: data.code,
              text: data.msg,
              icon: 'error',
            });
            return false;
          }
    
          if (!data.error) {
            Swal.fire({
              code: data.code,
              text: data.msg,
              icon: 'success',
            });
            
          }
    
        });
  
      await fetch(`https://webdev-alfasearch.ru/index-domains/link/`, {
        method: 'post',
        body: JSON.stringify({
          urls: formattedDomains,
          user: username
        }),
        headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => {
    
          if (data.error) {
            Swal.fire({
              text: data.message,
              status: data.status,
              icon: 'error',
            });
            return false;
          }
    
          if (!data.error) {
            Swal.fire({
              text: data.message,
              status: data.status,
              task_id: data.task_id,
              icon: 'success',
            });
            
          }
    
        });
        
    }

  const handleClick = (e) => {
    e.preventDefault();
    notification();
    indexingDomains();
  };

  return (
    <>
      <Header name={name} />

      <div className="index-form">
        <form id="initial-form" onSubmit={(e) => e.preventDefault()}>
          <h1 className="main">Indexing form</h1>
          <textarea
            name="domains"
            id="domains"
            placeholder="Вставьте url'ы"
            value={domains}
            onChange={(e) => setDomains(e.target.value)}
          />
          <div className="indexing" onClick={handleClick}>
            Отправить на индексацию
          </div>
        </form>
      </div>
    </>
  );
}

export default IndexPage;







