import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useUser } from "../contexts/userContext";

function AmpRotation({ net }) {
  const [time, setTime] = useState(0);
  const [period, setPeriod] = useState("");
  const [task, setTask] = useState(null);
  const { username } = useUser()

  const GET_TASKS_URL = "https://webdev-alfasearch.ru/tasks";
  const CREATE_TASK_URL = "https://webdev-alfasearch.ru/createTask";
  const DELETE_TASK_URL = "https://webdev-alfasearch.ru/deleteTask"

  const handleAddTask = async () => {
    const sendTask = async (net, task) => {

      const { id, ...taskWithoutId } = task;
      const payload = JSON.stringify({ initial: net, ...taskWithoutId });

      try {
        const response = await fetch(CREATE_TASK_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: payload,
        });

        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status}`);
        }

        return response.json();
      } catch (error) {
        return null;
      }
    };

    if (!time || !period) {
      Swal.fire({
        icon: "warning",
        title: "Неполные данные",
        text: "Пожалуйста, выберите время и периодичность для ротации.",
        confirmButtonText: "Ок",
      });
      return;
    }

    const newTask = {
      id: Date.now(),
      createdDate: new Date().toLocaleString(),
      createdBy: username,
      time,
      period,
    };
    const result = await sendTask(net, newTask);

    if (result) {
      Swal.fire({
        title: 'Задача добавлена!',
        text: `Задача на ротацию для проекта ${net} успешно добавлена.`,
        icon: 'success',
        confirmButtonText: 'OK'
      });
      setTask(newTask);
      setTime("");
      setPeriod("");
    }
  };

  const handleDeleteTask = async () => {
    const deleteTask = async (net) => {

      const payload = JSON.stringify({ projectName: net });

      try {
        const response = await fetch(DELETE_TASK_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: payload,
        });

        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status}`);
        }

        return response.json();
      } catch (error) {
        return null;
      }
    };

    const result = await deleteTask(net);

    if (result) {
      Swal.fire({
        title: 'Задача удалена!',
        text: `Задача для проекта ${net} успешно удалена.`,
        icon: 'success',
        confirmButtonText: 'OK'
      });
      setTask(null);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await fetch(GET_TASKS_URL);

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }

      const fetchedTasks = await response.json();
      return fetchedTasks;
    } catch (error) {
      console.error("Ошибка при получении задач:", error);
      return [];
    }
  };

  useEffect(() => {
    const loadTasks = async () => {
      const tasks = await fetchTasks();

      if (tasks?.length > 0) {
        const matchedTask = tasks.find((t) => t.initial.includes(net));
        if (matchedTask) {
          setTask(matchedTask);
        }
      }
    };

    loadTasks();
  }, [net]);

  return (
    <section className="section amp-rotation">
      <h2>Ротация ампов:</h2>

      {!task ? (
        <div className="settingsList">
          <div className="flex">
            <div className="column left-column">
              <h3>Выбор времени для ротации</h3>
              <div className="selectWrapper">
                <select
                  id="chooseRotationTime"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value="">от 00:00 до 23:00</option>
                  {Array.from({ length: 24 }, (_, i) => {
                    const formattedTime = `${String(i).padStart(2, "0")}:00`;
                    return (
                      <option key={i} value={formattedTime}>
                        {formattedTime}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="column right-column">
              <h3>Выбор периодичности ротации</h3>
              <div className="selectWrapper">
                <select
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <option value="">Выберите периодичность</option>
                  <option value="ежедневно">ежедневно</option>
                  <option value="через день">через день</option>
                  <option value="еженедельно">еженедельно</option>
                </select>
              </div>
            </div>
          </div>
          <button className="add-button" type="button" onClick={handleAddTask}>
            Добавить задачу
          </button>
        </div>
      ) : (
        <div className="settingsList task">
          <ul className="task-item">
            <li className="task-row">
              <span className="task-label">Задача создана:</span>
              <span className="task-data">
                {task.createdDate || "Дата неизвестна"}
              </span>
            </li>
            <li className="task-row">
              <span className="task-label">Назначил:</span>
              <span className="task-data">
                {task.createdBy || "Автор неизвестен"}
              </span>
            </li>
            <li className="task-row">
              <span className="task-label">Время ротации:</span>
              <span className="task-data">{task.time}</span>
            </li>
            <li className="task-row">
              <span className="task-label">Периодичность ротации:</span>
              <span className="task-data">{task.period}</span>
            </li>
            <li>
              <button
                className="delete-button"
                type="button"
                onClick={() => handleDeleteTask()}
              >
                Удалить
              </button>
            </li>
          </ul>
        </div>
      )}
    </section>
  );
}

export default AmpRotation;
