import React from "react";
import { useDrag } from "react-dnd";

const DraggableElement = ({ domain, dragType, customClass, onAction, ...props }) => {
  const name = domain.name ? domain.name : domain
  const [{ isDrag }, dragRef] = useDrag({
    type: dragType,
    item: {name},
    collect: (monitor) => ({
      isDrag: monitor.isDragging(),
    }),
  });

  const handleClick=()=>{
    onAction(domain)
  }

  const listItemStyle = customClass && customClass.listItem ? `list-item ${customClass.listItem}` : "list-item"
  return (
    <li
      ref={dragRef}
      className={isDrag ? "list-item dragged" : listItemStyle}
      id={name}
    >
      {name}
      <div className="flex">
      {props.children && props.children}
      <button className={customClass.button} onClick={handleClick}>
      </button></div>
    </li>
  );
}

export default DraggableElement;
