import React, { useContext, useState, useEffect, createContext } from "react";
import Loader from "../components/Loader";

const APIContext = createContext();
async function getProjects() {
    try {
      const response = await fetch("https://webdev-alfasearch.ru/servers/");
      const servers = await response.json();
  
      const domainsPromises = servers.map((server) => findDomains(server));
      const domains = await Promise.all(domainsPromises);
  
      return domains.flat();
    } catch (error) {
      console.error("Ошибка при получении проектов:", error);
      return []; 
    }
  }

async function findDomains(server) {
    try {
      const response = await fetch(`http://${server}:7000/projects/extended`);
      const ipDomains = await response.json();
      return ipDomains.map((e) => ({
        ...e,
        server, 
      }));
    } catch (error) {
      console.error(`Ошибка при получении доменов с сервера ${server}:`, error);
      return []; 
    }
  }
export function APIContextProvider({ children }) {
  const [domainsE, setDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const domains = await getProjects();
      setDomains(domains);
      setLoading(false);
    };
    fetchData()
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <APIContext.Provider value={{ domainsE, setDomains }}>
          {children}
        </APIContext.Provider>
      )}
    </>
  );
}

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Контекст не обернут в провайдер");
  }
  return context;
}
