import React from "react";

function ToggleAmpCheckbox({ isChecked, handleInput }) {
  return (
      <div className={`switch-amp ${isChecked ? "active" : ""}`}>
        <input
          type="checkbox"
          id="toggle"
          checked={isChecked}
          onChange={handleInput}
        />
        <span id="toggle-label">
          {isChecked ? "Отключить AMP-сетку" : "Включить AMP-сетку"}
        </span>
        <label htmlFor="toggle" className="slider"></label>
      </div>

  );
}

export default ToggleAmpCheckbox;
