import "./App.css";
import { Routes, Route } from "react-router-dom";
import Projects from "./pages/Projects";
import Project from "./pages/Project";

import Auth from "./pages/Auth";
import Сhecklist from "./pages/Сhecklist";
import IndexPage from "./pages/IndexPage";
import { useState } from "react";
import { APIContextProvider } from "./contexts/apiContext";
import PageNotFound from "./pages/PageNotFound";

function App() {
  const [user, setUser] = useState(null);
  const handleUser = (userData) => {
    setUser(userData);
  };

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Auth onLogin={handleUser} />} />
        <Route path="/check-list" element={<Сhecklist name={user} />} />
        <Route path="/index-page" element={<IndexPage name={user} />} />
        <Route
          path="/projects"
          element={
            <APIContextProvider>
              <Projects name={user} />
            </APIContextProvider>
          }
        />
        <Route
          path="/projects/:net/"
          element={
            <APIContextProvider>
              <Project name={user} />
            </APIContextProvider>
          }
        />
        <Route path="/*" element={<PageNotFound />}/>
      </Routes>
    </div>
  );
}

export default App;
