import React, { useMemo, useState } from "react";
import DraggableElement from "./DraggableElement";

function AllDomainsList({ filteredList, onAddToAmpList, isAmpProject }) {
  const [showAll, setShowAll] = useState(false);

  const DOMAINS_CLASSES = useMemo(() => ({
    listItem: "canonical",
    button: "make-amp-button",
  }), []);
  const MAX_VISIBLE_DOMAINS = 9;
  const displayedList = showAll ? filteredList : filteredList.slice(0, MAX_VISIBLE_DOMAINS);

  return (
    <div className="dnd-box">
      <h3>Все домены</h3>
      <ul>
        {displayedList.length > 0 ? (
          displayedList.map((domain) => {
            const { name, isAmp } = domain;
            return isAmpProject && isAmp ? (
              <li key={name} className="list-item amp" id={name}>
                {name}
              </li>
            ) : isAmpProject ? (
              <DraggableElement
                domain={domain}
                dragType="domains"
                customClass={DOMAINS_CLASSES}
                key={name}
                onAction={onAddToAmpList}
              />
            ) : (
              <li key={name} className="list-item" id={name}>
                {name}
              </li>
            );
          })
        ) : (
          <li>
            <p>Ничего не найдено.</p>
          </li>
        )}
      </ul>
      {filteredList.length > MAX_VISIBLE_DOMAINS && (
        <button type="button" onClick={() => setShowAll(!showAll)} className="show-more-button">
          {showAll ? "Скрыть" : "Показать все"}
        </button>
      )}
    </div>
  );
}

export default AllDomainsList;
