import React from "react";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <main className="page-not-found">
      <div className="wrap">
        <h1>404</h1>
        <h2>Страница не найдена</h2>
        <p>Похоже, здесь пусто... Попробуем что-то другое?</p>
        <button onClick={handleGoHome}>Вернуться на главную</button>
      </div>
    </main>
  );
}

export default PageNotFound;
